<template>
  <div class="pa-2">
    <v-row
      v-if="$vuetify.breakpoint.lgAndUp"
      no-gutters
      class="pa-2"
      :class="darkmode || 'white'"
    >
      <v-col cols="12" sm="4">
        <div
          class="font-weight-bold d-inline-block"
          style="cursor: pointer; width: 100px"
        >
          <v-btn @click="$router.go(-1)" depressed>
            <v-icon>mdi-arrow-left</v-icon>
            <span v-if="$vuetify.breakpoint.lgAndUp">
              {{ $t("app.back") }}
            </span>
          </v-btn>
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="8" class="d-flex justify-end">
        <v-select
          v-model="grade"
          :label="$t('school_year.header.select_class')"
          :items="gradeList"
          item-value="id"
          item-text="grade"
          class="select-200 d-inline-block mr-2"
          multiple
          outlined
          dense
          hide-details
          @change="changeGrade"
        >
          <template slot="prepend-item">
            <v-list-item @click="handleSelectAll">
              <v-list-item-action>
                <v-checkbox :value="selectAllGrade" />
              </v-list-item-action>
              <v-list-item-content :class="selectAllGrade && 'primary--text'">
                {{ $t("app.select_all") }}
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
            <span v-if="index === 1" class="grey--text">
              (+{{ grade.length - 1 }} {{ $t("app.others") }})
            </span>
          </template>
        </v-select>
        <v-select
          v-model="idLesson"
          :label="$t('app.select') + ' ' + $t('app.subjects')"
          :items="subjects.data"
          :loading="subjects.loading"
          :item-text="
            item =>
              `${item.lesson_name} - ${$t('app.grade')} ${item.grade_name}`
          "
          item-value="lesson"
          outlined
          hide-details
          dense
          class="mr-2 select-225 d-inline-block"
          @change="changeLesson"
        />

        <v-btn
          class="gradient-primary caption mr-2"
          depressed
          dark
          dense
          @click="openDialog"
        >
          <span v-if="$vuetify.breakpoint.lgAndUp" class="mr-2">
            {{ $t("e_test.add_question") }}
          </span>
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <!-- <v-btn
          :disabled="dataTable.length === 0"
          class="gradient-primary caption mr-2"
          depressed
          dark
          dense
        >
          <download-excel :name="'excel.xls'">
            {{ $t("e_test.download_question")
            }}<v-icon class="ml-2">mdi-cloud-download</v-icon>
          </download-excel>
        </v-btn> -->
      </v-col>
    </v-row>

    <div v-else class="d-flex align-center pa-2">
      <div
        class="font-weight-bold d-inline-block"
        style="cursor: pointer; width: 100px"
      >
        <v-btn @click="$router.go(-1)" depressed>
          <v-icon>mdi-arrow-left</v-icon>
          <span v-if="$vuetify.breakpoint.lgAndUp">
            {{ $t("app.back") }}
          </span>
        </v-btn>
      </div>
      <v-spacer />
      <v-btn
        color="primary"
        @click="bottomMenu = !bottomMenu"
        depressed
        class="mr-1"
      >
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
      <v-speed-dial
        v-model="menuSubject"
        direction="bottom"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="menuSubject" depressed color="primary" dark>
            <v-icon v-if="menuSubject">mdi-close</v-icon>
            <v-icon v-else>mdi-menu-down-outline</v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" depressed fab dense small @click="openDialog"
              ><v-icon color="primary">mdi-plus-circle-outline</v-icon></v-btn
            >
          </template>
          <span>{{ $t("e_test.add_question") }}</span>
        </v-tooltip>
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="dataTable.length === 0"
              depressed
              fab
              dense
              small
            >
              <download-excel :name="'excel.xls'">
                <v-icon color="primary">mdi-cloud-download</v-icon>
              </download-excel>
            </v-btn>
          </template>
          <span>{{ $t("e_test.download_question") }}</span>
        </v-tooltip> -->
      </v-speed-dial>
      <v-bottom-sheet v-model="bottomMenu">
        <v-sheet class="text-center pa-6">
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-select
                v-model="grade"
                :label="$t('school_year.header.select_class')"
                :items="gradeList"
                item-value="id"
                item-text="grade"
                class="mt-2"
                multiple
                outlined
                dense
                hide-details
                @change="changeGrade"
              >
                <template slot="prepend-item">
                  <v-list-item @click="handleSelectAll">
                    <v-list-item-action>
                      <v-checkbox :value="selectAllGrade" />
                    </v-list-item-action>
                    <v-list-item-content
                      :class="selectAllGrade && 'primary--text'"
                    >
                      {{ $t("app.select_all") }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #selection="{ item, index }">
                  <span v-if="index === 0" class="mr-2">{{ item.grade }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ grade.length - 1 }} {{ $t("app.others") }})
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="idLesson"
                :label="$t('app.select') + ' ' + $t('app.subjects')"
                :items="subjects.data"
                :loading="subjects.loading"
                :item-text="item => `${item.lesson_name} - ${item.grade_name}`"
                item-value="lesson"
                outlined
                hide-details
                dense
                class="mt-2"
                @change="changeLesson"
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import { get_grade_list } from "@/api/admin/schoolClass";

export default {
  props: {
    darkmode: Boolean,
    subjects: Object,
    dataTable: Array
  },
  created() {
    this.getGradeList();
  },
  data() {
    return {
      selectAllGrade: false,
      idLesson: 0,
      menuSubject: false,
      gradeList: [],
      grade: [],
      bottomMenu: false
    };
  },
  methods: {
    handleSelectAll() {
      this.selectAllGrade = !this.selectAllGrade;
      this.grade = [];
      if (this.selectAllGrade) this.grade = this.gradeList.map(r => r.id);
      this.changeGrade(this.selectAllGrade);
    },
    async getGradeList() {
      const res = await get_grade_list();
      if (res.data) this.gradeList = res.data.data;
    },
    changeLesson(id) {
      this.$emit("changeSubjects", id);
    },
    changeGrade(selectAll) {
      this.selectAllGrade = selectAll;
      this.$emit("changeGrade", { grade_id: this.grade });
    },
    openDialog() {
      this.$emit("dialogAdd");
    }
  }
};
</script>
